import Section from "./section.js";
import Tab from "./tab.js";
import Column from "./column.js";

frappe.ui.form.Layout = class Layout {
	constructor(opts) {
		this.views = {};
		this.pages = [];
		this.tabs = [];
		this.sections = [];
		this.page_breaks = [];
		this.fields_list = [];
		this.fields_dict = {};

		$.extend(this, opts);
	}

	make() {
		if (!this.parent && this.body) {
			this.parent = this.body;
		}
		this.wrapper = $('<div class="form-layout">')
			.appendTo(this.parent)
			.toggleClass("card-body", !this.hide_tabs);
		this.message = $('<div class="form-message hide"></div>').appendTo(
			this.wrapper
		);
		this.page = $(
			`<div class="form-page d-flex flex-column flex-lg-row-fluid ${
				this.child_table ? "gap-3 gap-5" : "gap-7 gap-lg-10"
			}"></div>`
		).appendTo(this.wrapper);

		if (!this.fields) {
			this.fields = this.get_doctype_fields();
		}

		this.setup_info_section();

		if (this.is_tabbed_layout()) {
			this.setup_tabbed_layout();
		}

		this.setup_tab_events();
		this.render();
	}

	setup_info_section() {
		if (!this.frm) {
			return;
		}

		const follow_class = this.frm.get_docinfo().is_document_followed
			? "btn-success"
			: "btn-light";
		const follow_label = this.frm.get_docinfo().is_document_followed
			? "Followed"
			: "Follow";

		const like_class = frappe.ui.is_liked(this.frm.doc)
			? "liked-by liked btn-primary"
			: "not-liked btn-light-primary";

		const like_label = frappe.ui.is_liked(this.frm.doc) ? "Unlike" : "Like";
		this.info_section = $(`<div class="card row">
          <div class="card-body">
            <div class="d-flex flex-wrap flex-sm-nowrap info-wrapper mb-6">
              <div class="me-7 mb-4 avatar-container overlay">
								<div class="overlay-wrapper avatar-image"></div>
								 <div class="overlay-layer bg-dark bg-opacity-25">
                    <btn class="btn btn-light-warning btn-sm btn-shadow avatar-change-image">
										${__("Change")}
										</btn>
                    <btn  class="btn btn-light-danger btn-sm btn-shadow ms-2 avatar-remove-image">
										${__("Remove")}
										</btn>
                </div>
              </div>
              <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                      <div class="text-gray-800 fs-2 fw-bold me-1 info-title cursor-copy"></div>
                    </div>
                    <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 info-description"></div>
                  </div>
                  <div class="d-flex my-4 info-actions">
                    <btn class="follow-btn btn btn-light-success btn-icon-success btn-sm ${follow_class} me-2">
                      <i class="fa-duotone fa-bell fs-6"></i>
											${__(follow_label)}
                    </btn>
                    <btn class="btn btn-sm btn-icon-warning btn-light-warning me-2 assign-btn add-assignment-btn">
                      <i class="fa-duotone fa-user-plus fs-6"></i>
											${__("Assign")}
                    </btn>
                    <div class="me-0">
                      <btn class="btn btn-sm btn-icon-primary btn-light-primary like-btn ${like_class}" 
													 data-doctype="${this.frm.doctype}" 
													 data-name="${this.frm.doc.name}">
                        <i class="fa fa-thumbs-up fs-6"></i>
												${__(like_label)}
                      </btn>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap justify-content-start">
                  <div class="d-flex flex-wrap">
                    <div class="d-flex flex-wrap info-stats"></div>
										<div class="assignments symbol-group symbol-hover mb-3"></div>
                  </div>
                </div>
              </div>
            </div>
						<div class="separator"></div>
          </div>
      </div>`);

		if (this.is_info_layout()) {
			this.setup_info_avatar();
			this.setup_info_title();
			this.setup_info_description();
			this.setup_info_stats();
			this.setup_info_actions();
		}

		this.info_section.prependTo(this.page);
	}

	get_info_image() {
		return (
			this.frm.doc[this.frm.meta.image_field] || this.frm.doc.image || null
		);
	}

	get_info_title() {
		return this.frm.doc[this.frm.meta.title_field] || this.frm.doc.name;
	}

	setup_info_avatar() {
		const $wrapper = this.info_section.find(".avatar-container");
		const $image = $wrapper.find(".avatar-image");
		$image.empty();

		const image = this.get_info_image();
		const name = this.get_info_title();

		if (!image) {
			const color = frappe.random_color(name);
			let icon = this.frm.doc.icon;

			if (!icon && this.frm.meta.is_single) {
				icon = this.frm.meta.icon;
			}

			if (icon) {
				this.setup_icon_wrapper(color, icon).appendTo($image);
				return;
			}
		}

		$wrapper
			.find(".overlay-layer")
			.toggleClass("hide", !this.frm.meta.image_field);

		$wrapper.find(".avatar-remove-image").toggleClass("hide", !image);

		$(
			frappe.get_avatar(
				"symbol symbol-100px symbol-lg-160px symbol-fixed position-relative avatar-xl",
				name,
				image
			)
		).appendTo($image);
	}

	setup_icon_wrapper(color, icon) {
		return $(`<div class="symbol symbol-160px">
                <div class="bg-light-${color} symbol-label">
                    <i class="${icon} fs-5x text-inverse-${color}"></i>
                </div>
              </div>`);
	}

	setup_info_title() {
		const name = this.get_info_title();
		this.info_section.find(".info-title").text(name);
	}

	setup_info_description() {
		const descriptions = this.fields.filter(
			element => element.is_info_description == true
		);

		if (descriptions.length) {
			for (const desc in descriptions) {
				const row = descriptions[desc];
				const value = this.frm.doc[row.fieldname];
				if (value) {
					$(`<div class="d-flex align-items-center text-gray-500 me-5 mb-2">
            <i class="fa-duotone fa-${row.icon || "tag"} fs-4 me-1"></i>
            ${this.frm.doc[row.fieldname]}
          </div>`).appendTo(this.info_section.find(".info-description"));
				}
			}
			return;
		}

		$(
			`<span class="d-flex align-items-center text-gray-500 me-5 mb-2">${"# " +
				__(this.frm.doc.description || this.frm.meta.description)}</span>`
		).appendTo(this.info_section.find(".info-description"));
	}

	get_assingment_section() {
		return this.info_section.find(".assignments");
	}

	setup_info_stats() {
		let stats = this.fields.filter(element => element.show_on_stats == true);
		const forbiddenTypes = ["Table", "MultiSelect", "Long Text"];

		if (stats.length === 0) {
			stats = this.fields
				.filter(
					element =>
						element.reqd == 1 &&
						element.is_info_description != 1 &&
						!element.fieldtype.includes(forbiddenTypes) &&
						element.fieldname != "__newname"
				)
				.slice(0, 3);
		}

		if (stats.length) {
			for (const stat in stats) {
				const row = stats[stat];
				const value = this.frm.doc[row.fieldname];
				if (value) {
					$(`<div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
              <div class="d-flex align-items-center">
                <div class="fs-4 fw-bold counted">
                  ${value}
                </div>
              </div>
              <div class="fw-semibold fs-6 text-gray-500">${row.label}</div>
          </div>`).appendTo(this.info_section.find(".info-stats"));
				}
			}
		}
	}

	setup_info_actions() {
		const follow_btn = this.info_section.find(".follow-btn");
		const assign_btn = this.info_section.find(".assign-btn");
		const like_btn = this.info_section.find(".like-btn");
		const me = this;

		this.info_section.on(
			"click",
			".avatar-change-image, .avatar-remove-image",
			function(e) {
				let $target = $(e.currentTarget);
				var field = me.frm.get_field(me.frm.meta.image_field);
				if ($target.is(".avatar-change-image")) {
					if (!field.$input) {
						field.make_input();
					}
					field.$input.trigger("attach_doc_image");
				} else {
					me.frm.attachments.remove_attachment_by_filename(
						me.frm.doc[me.frm.meta.image_field],
						function() {
							field.set_value("").then(() => me.frm.save());
						}
					);
				}
			}
		);

		follow_btn.on("click", () => {
			let is_followed = this.frm.get_docinfo().is_document_followed;
			frappe
				.call("frappe.desk.form.document_follow.update_follow", {
					doctype: this.frm.doctype,
					doc_name: this.frm.doc.name,
					following: !is_followed
				})
				.then(() => {
					frappe.model.set_docinfo(
						this.frm.doctype,
						this.frm.doc.name,
						"is_document_followed",
						!is_followed
					);
				});
		});

		like_btn.on("click", frappe.ui.click_toggle_like);
	}

	setup_tabbed_layout() {
		const $tabs = $(`
			<div class="form-tabs-list">
				<ul class="nav form-tabs nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold form-nav" id="form-tabs" role="tablist"></ul>
			</div>
		`).appendTo(this.info_section?.find(".card-body"));

		this.tabs_list = $tabs.find(".form-tabs");
		this.tabs_content = $(
			`<div class="form-tab-content tab-content"></div>`
		).appendTo(this.page);
		this.setup_events();
	}

	get_doctype_fields() {
		let fields = [this.get_new_name_field()];
		if (this.doctype_layout) {
			fields = fields.concat(this.get_fields_from_layout());
		} else {
			fields = fields.concat(
				frappe.meta.sort_docfields(frappe.meta.docfield_map[this.doctype])
			);
		}

		return fields;
	}

	get_new_name_field() {
		return {
			parent: this.frm.doctype,
			fieldtype: "Data",
			fieldname: "__newname",
			reqd: 1,
			hidden: 1,
			label: __("Name"),
			get_status: function(field) {
				if (
					field.frm &&
					field.frm.is_new() &&
					field.frm.meta.autoname &&
					["prompt", "name"].includes(field.frm.meta.autoname.toLowerCase())
				) {
					return "Write";
				}
				return "None";
			}
		};
	}

	get_fields_from_layout() {
		const fields = [];
		for (let f of this.doctype_layout.fields) {
			const docfield = copy_dict(
				frappe.meta.docfield_map[this.doctype][f.fieldname]
			);
			docfield.label = f.label;
			fields.push(docfield);
		}
		return fields;
	}

	show_message(html, color) {
		const color_map = {
			yellow: "warning",
			blue: "primary",
			red: "danger",
			green: "success",
			orange: "warning"
		};

		const icon_map = {
			yellow: "triangle-exclamation",
			blue: "circle-info",
			red: "shield-xmark",
			green: "shield-check",
			orange: "triangle-exclamation"
		};
		if (this.message_color) {
			// remove previous color
			this.message.removeClass(this.message_color);
		}
		this.message_color =
			color && ["yellow", "blue", "red", "green", "orange"].includes(color)
				? color
				: "blue";
		if (html) {
			// wrap in a block
			html = `<i class="fa-duotone fa-${icon_map[this.message_color]} text-${
				color_map[this.message_color]
			} fs-1 me-3"></i>
								<div class="d-flex flex-column">
									${html}
								</div>
				`;

			this.message
				.removeClass("hide")
				.addClass(
					`alert alert-${
						color_map[this.message_color]
					} d-flex align-items-center p-5`
				);
			$(html).appendTo(this.message);
		} else {
			this.message.empty().addClass("hide");
		}
	}

	render(new_fields) {
		let fields = new_fields || this.fields;

		this.section = null;
		this.column = null;

		if (this.no_opening_section() && !this.is_tabbed_layout()) {
			this.fields.unshift({ fieldtype: "Section Break" });
		}

		if (this.is_tabbed_layout()) {
			// add a tab without `fieldname` to avoid conflicts
			let default_tab = {
				label: __("Details"),
				fieldtype: "Tab Break",
				fieldname: "__details"
			};

			let attachment_tab = {
				label: __("Attachments"),
				fieldtype: "Tab Break",
				fieldname: "__attach",
				attach: true
			};

			let share_tab = {
				label: __("Share"),
				fieldtype: "Tab Break",
				fieldname: "__share",
				share: true
			};

			let activity_tab = {
				label: __("Activity"),
				fieldtype: "Tab Break",
				fieldname: "__activity",
				activity: true
			};

			let first_field_visible = this.fields.find(
				element => element.hidden == false
			);
			let first_tab =
				first_field_visible?.fieldtype === "Tab Break"
					? first_field_visible
					: null;

			this.fields.splice(
				this.fields.length,
				0,
				attachment_tab,
				share_tab,
				activity_tab
			);

			if (!first_tab) {
				this.fields.splice(0, 0, default_tab);
			} else {
				// reshuffle __newname field to accomodate under 1st Tab Break
				let newname_field = this.fields.find(
					df => df.fieldname === "__newname"
				);
				if (newname_field && newname_field.get_status(this) === "Write") {
					this.fields.splice(0, 1);
					this.fields.splice(1, 0, newname_field);
				}
			}
		}

		fields.forEach(df => {
			switch (df.fieldtype) {
				case "Fold":
					this.make_page(df);
					break;
				case "Page Break":
					this.make_page_break();
					this.make_section(df);
					break;
				case "Section Break":
					this.make_section(df);
					break;
				case "Column Break":
					this.make_column(df);
					break;
				case "Tab Break":
					this.make_tab(df);
					break;
				default:
					this.make_field(df);
			}
		});
	}

	no_opening_section() {
		return (
			(this.fields[0] && this.fields[0].fieldtype != "Section Break") ||
			!this.fields.length
		);
	}

	no_opening_tab() {
		return (
			(this.fields[1] && this.fields[1].fieldtype != "Tab Break") ||
			!this.fields.length
		);
	}

	is_tabbed_layout() {
		return (
			this.fields.find(f => f.fieldtype === "Tab Break") ||
			(this.frm && frappe.boot.desk_settings.form_sidebar && !this.hide_tabs)
		);
	}

	is_info_layout() {
		if (this.frm && !this.disable_info_layout && !this.frm.doc.__islocal) {
			return (
				this.fields.find(
					f => f.fieldname === "icon" || f.fieldname === "image"
				) ||
				this.frm.meta.image_field != null ||
				(this.frm.meta.icon != null && this.frm.meta.issingle)
			);
		} else {
			return false;
		}
	}

	replace_field(fieldname, df, render) {
		df.fieldname = fieldname; // change of fieldname is avoided
		if (this.fields_dict[fieldname] && this.fields_dict[fieldname].df) {
			const prev_fieldobj = this.fields_dict[fieldname];
			const fieldobj = this.init_field(df, prev_fieldobj.parent, render);
			prev_fieldobj.$wrapper.replaceWith(fieldobj.$wrapper);
			const idx = this.fields_list.findIndex(e => e == prev_fieldobj);
			this.fields_list.splice(idx, 1, fieldobj);
			this.fields_dict[fieldname] = fieldobj;
			this.sections.forEach(section =>
				section.replace_field(fieldname, fieldobj)
			);
			prev_fieldobj.tab?.replace_field(fieldobj);
			this.refresh_fields([df]);
		}
	}

	make_field(df, colspan, render) {
		!this.section && this.make_section();
		!this.column && this.make_column();

		const parent = this.column.wrapper.get(0);
		const fieldobj = this.init_field(df, parent, render);
		this.fields_list.push(fieldobj);
		this.fields_dict[df.fieldname] = fieldobj;

		this.section.fields_list.push(fieldobj);
		this.section.fields_dict[df.fieldname] = fieldobj;
		fieldobj.section = this.section;

		if (this.current_tab) {
			fieldobj.tab = this.current_tab;
			this.current_tab.fields_list.push(fieldobj);
			this.current_tab.fields_dict[df.fieldname] = fieldobj;
		}
	}

	init_field(df, parent, render = false) {
		const fieldobj = frappe.ui.form.make_control({
			df: df,
			doctype: this.doctype,
			parent: parent,
			frm: this.frm,
			render_input: render,
			doc: this.doc,
			layout: this
		});

		fieldobj.layout = this;
		return fieldobj;
	}

	make_page_break() {
		this.page = $('<div class="form-page page-break"></div>').appendTo(
			this.wrapper
		);
	}

	make_page(df) {
		let me = this;
		let head = $(`
			<div class="form-clickable-section text-center">
				<a class="btn-fold h6 text-muted">
					${__("Show more details")}
				</a>
			</div>
		`).appendTo(this.wrapper);

		this.page = $('<div class="form-page second-page hide"></div>').appendTo(
			this.wrapper
		);

		this.fold_btn = head.find(".btn-fold").on("click", function() {
			let page = $(this)
				.parent()
				.next();
			if (page.hasClass("hide")) {
				$(this)
					.removeClass("btn-fold")
					.html(__("Hide details"));
				page.removeClass("hide");
				frappe.utils.scroll_to($(this), true, 30);
				me.folded = false;
			} else {
				$(this)
					.addClass("btn-fold")
					.html(__("Show more details"));
				page.addClass("hide");
				me.folded = true;
			}
		});

		this.section = null;
		this.folded = true;
	}

	unfold() {
		this.fold_btn.trigger("click");
	}

	make_section(df) {
		this.section = new Section(
			this.current_tab ? this.current_tab.wrapper : this.page,
			df,
			this.card_layout,
			this
		);

		// append to layout fields
		if (df) {
			this.fields_dict[df.fieldname] = this.section;
			this.fields_list.push(this.section);
		}

		this.column = null;
	}

	make_column(df) {
		this.column = new Column(this.section, df);
		if (df && df.fieldname) {
			this.fields_list.push(this.column);
		}
	}

	make_tab(df) {
		this.section = null;
		let tab = new Tab(this, df, this.frm, this.tabs_list, this.tabs_content);
		this.current_tab = tab;
		this.make_section({ fieldtype: "Section Break" });
		this.tabs.push(tab);
		if (df.attach) {
			this.attach_tab = tab;
		}

		if (df.share) {
			this.share_tab = tab;
		}

		if (df.activity) {
			this.activity_tab = tab;
		}

		return tab;
	}

	refresh(doc) {
		if (doc) this.doc = doc;

		if (this.frm) {
			this.wrapper.find(".empty-form-alert").remove();
		}

		// NOTE this might seem redundant at first, but it needs to be executed when frm.refresh_fields is called
		this.attach_doc_and_docfields(true);

		if (this.frm && this.frm.wrapper) {
			$(this.frm.wrapper).trigger("refresh-fields");
		}

		// dependent fields
		this.refresh_dependency();

		// refresh sections
		this.refresh_sections();

		if (this.frm) {
			// collapse sections
			this.refresh_section_collapse();
		}

		if (document.activeElement) {
			if (
				document.activeElement.tagName == "INPUT" &&
				this.is_numeric_field_active()
			) {
				document.activeElement.select();
			}
		}
	}

	is_numeric_field_active() {
		const control = $(document.activeElement).closest(".frappe-control");
		const fieldtype = (control.data() || {}).fieldtype;
		return frappe.model.numeric_fieldtypes.includes(fieldtype);
	}

	refresh_sections() {
		// hide invisible sections
		this.wrapper.find(".form-section:not(.hide-control)").each(function() {
			const section = $(this).removeClass("empty-section visible-section");
			if (section.find(".frappe-control:not(.hide-control)").length) {
				section.addClass("visible-section");
			} else {
				// nothing visible, hide the section
				section.addClass("empty-section");
			}
		});

		this.refresh_info_layout();

		// refresh tabs
		this.is_tabbed_layout() && this.refresh_tabs();
	}

	refresh_tabs() {
		for (let tab of this.tabs) {
			tab.refresh();
		}

		const visible_tabs = this.tabs.filter(tab => !tab.hidden);
		if (visible_tabs && visible_tabs.length == 1) {
			visible_tabs[0].parent.toggleClass("hide show");
		}
		this.set_tab_as_active();
	}

	set_tab_as_active() {
		let frm_active_tab = this?.frm.get_active_tab?.();
		if (frm_active_tab) {
			frm_active_tab.set_active();
		} else if (this.tabs.length) {
			// set first tab as active when opening for first time, or new doc
			let first_visible_tab = this.tabs.find(tab => !tab.is_hidden());
			first_visible_tab && first_visible_tab.set_active();
		}
	}

	refresh_info_layout() {
		if (!this.frm && !this.info_section) {
			return;
		}

		this.info_section.toggleClass("hide", this.disable_info_layout || false);

		this.info_section
			.find(".info-wrapper")
			.toggleClass("hide", !this.is_info_layout());

		this.info_section
			.find(".card-body")
			.toggleClass("pb-0 pt-9", this.is_info_layout());

		this.info_section
			.find(".separator")
			.toggleClass("hide", !this.is_info_layout());

		this.setup_info_avatar();
		this.setup_info_title();
		this.info_section.find(".info-description").empty();
		this.setup_info_description();
		this.info_section.find(".info-stats").empty();
		this.setup_info_stats();
	}

	refresh_fields(fields) {
		let fieldnames = fields.map(field => {
			if (field.fieldname) return field.fieldname;
		});

		this.fields_list.map(fieldobj => {
			if (fieldnames.includes(fieldobj.df.fieldname)) {
				fieldobj.refresh();
				if (fieldobj.df["default"]) {
					fieldobj.set_input(fieldobj.df["default"]);
				}
			}
		});
	}

	add_fields(fields) {
		this.render(fields);
		this.refresh_fields(fields);
	}

	refresh_section_collapse() {
		if (!(this.sections && this.sections.length)) return;

		for (let i = 0; i < this.sections.length; i++) {
			let section = this.sections[i];
			let df = section.df;
			if (df && df.collapsible) {
				let collapse = true;

				if (df.collapsible_depends_on) {
					collapse = !this.evaluate_depends_on_value(df.collapsible_depends_on);
				}

				if (collapse && section.has_missing_mandatory()) {
					collapse = false;
				}

				section.collapse(collapse);
			}
		}
	}

	attach_doc_and_docfields(refresh) {
		let me = this;
		for (let i = 0, l = this.fields_list.length; i < l; i++) {
			let fieldobj = this.fields_list[i];
			if (me.doc) {
				fieldobj.doc = me.doc;
				fieldobj.doctype = me.doc.doctype;
				fieldobj.docname = me.doc.name;
				fieldobj.df =
					frappe.meta.get_docfield(
						me.doc.doctype,
						fieldobj.df.fieldname,
						me.doc.name
					) || fieldobj.df;
			}
			refresh && fieldobj.df && fieldobj.refresh && fieldobj.refresh();
		}
	}

	refresh_section_count() {
		this.wrapper.find(".section-count-label:visible").each(function(i) {
			$(this).html(i + 1);
		});
	}

	setup_events() {
		let last_scroll = 0;
		let tabs_list = $(".form-tabs-list");
		let tabs_content = this.tabs_content[0];
		if (!tabs_list.length) return;

		$(window).scroll(
			frappe.utils.throttle(() => {
				let current_scroll = document.documentElement.scrollTop;
				if (current_scroll > 0 && last_scroll <= current_scroll) {
					tabs_list.removeClass("form-tabs-sticky-down");
					tabs_list.addClass("form-tabs-sticky-up");
				} else {
					tabs_list.removeClass("form-tabs-sticky-up");
					tabs_list.addClass("form-tabs-sticky-down");
				}
				last_scroll = current_scroll;
			}, 500)
		);

		this.tabs_list.off("click").on("click", ".nav-link", e => {
			e.preventDefault();
			e.stopImmediatePropagation();
			$(e.currentTarget).tab("show");
			if (tabs_content.getBoundingClientRect().top < 100) {
				tabs_content.scrollIntoView();
				setTimeout(() => {
					$(".page-head").css("top", "-15px");
					$(".form-tabs-list").removeClass("form-tabs-sticky-down");
					$(".form-tabs-list").addClass("form-tabs-sticky-up");
				}, 3);
			}
		});
	}

	setup_tab_events() {
		this.wrapper.on("keydown", ev => {
			if (ev.which == 9) {
				let current = $(ev.target);
				let doctype = current.attr("data-doctype");
				let fieldname = current.attr("data-fieldname");
				if (doctype) {
					return this.handle_tab(doctype, fieldname, ev.shiftKey);
				}
			}
		});
	}

	handle_tab(doctype, fieldname, shift) {
		let grid_row = null,
			prev = null,
			fields = this.fields_list,
			focused = false;

		// in grid
		if (doctype != this.doctype) {
			grid_row = this.get_open_grid_row();
			if (!grid_row || !grid_row.layout) {
				return;
			}
			fields = grid_row.layout.fields_list;
		}

		for (let i = 0, len = fields.length; i < len; i++) {
			if (fields[i].df.fieldname == fieldname) {
				if (shift) {
					if (prev) {
						this.set_focus(prev);
					} else {
						$(this.primary_button).focus();
					}
					break;
				}
				if (i < len - 1) {
					focused = this.focus_on_next_field(i, fields);
				}

				if (focused) {
					break;
				}
			}
			if (this.is_visible(fields[i])) prev = fields[i];
		}

		if (!focused) {
			// last field in this group
			if (grid_row) {
				// in grid
				if (grid_row.doc.idx == grid_row.grid.grid_rows.length) {
					// last row, close it and find next field
					grid_row.toggle_view(false, function() {
						grid_row.grid.frm.layout.handle_tab(
							grid_row.grid.df.parent,
							grid_row.grid.df.fieldname
						);
					});
				} else {
					// next row
					grid_row.grid.grid_rows[grid_row.doc.idx].toggle_view(true);
				}
			} else if (!shift) {
				// End of tab navigation
				$(this.primary_button).focus();
			}
		}

		return false;
	}

	focus_on_next_field(start_idx, fields) {
		// loop to find next eligible fields
		for (let i = start_idx + 1, len = fields.length; i < len; i++) {
			let field = fields[i];
			if (this.is_visible(field)) {
				if (field.df.fieldtype === "Table") {
					// open table grid
					if (!(field.grid.grid_rows && field.grid.grid_rows.length)) {
						// empty grid, add a new row
						field.grid.add_new_row();
					}
					// show grid row (if exists)
					field.grid.grid_rows[0].show_form();
					return true;
				} else if (!in_list(frappe.model.no_value_type, field.df.fieldtype)) {
					this.set_focus(field);
					return true;
				}
			}
		}
	}

	is_visible(field) {
		return (
			field.disp_status === "Write" &&
			field.df &&
			"hidden" in field.df &&
			!field.df.hidden
		);
	}

	set_focus(field) {
		if (field.tab) {
			field.tab.set_active();
		}
		// next is table, show the table
		if (field.df.fieldtype == "Table") {
			if (!field.grid.grid_rows.length) {
				field.grid.add_new_row(1);
			} else {
				field.grid.grid_rows[0].toggle_view(true);
			}
		} else if (field.editor) {
			field.editor.set_focus();
		} else if (field.$input) {
			field.$input.focus();
		}
	}

	get_open_grid_row() {
		return $(".grid-row-open").data("grid_row");
	}

	refresh_dependency() {
		/**
			Resolve "depends_on" and show / hide accordingly
			build dependants' dictionary
		*/

		let has_dep = false;

		const fields = this.fields_list.concat(this.tabs);

		for (let fkey in fields) {
			let f = fields[fkey];
			if (
				f.df.depends_on ||
				f.df.mandatory_depends_on ||
				f.df.read_only_depends_on
			) {
				has_dep = true;
				break;
			}
		}

		if (!has_dep) return;

		// show / hide based on values
		for (let i = fields.length - 1; i >= 0; i--) {
			let f = fields[i];
			f.guardian_has_value = true;
			if (f.df.depends_on) {
				// evaluate guardian

				f.guardian_has_value = this.evaluate_depends_on_value(f.df.depends_on);

				// show / hide
				if (f.guardian_has_value) {
					if (f.df.hidden_due_to_dependency) {
						f.df.hidden_due_to_dependency = false;
						f.refresh();
					}
				} else {
					if (!f.df.hidden_due_to_dependency) {
						f.df.hidden_due_to_dependency = true;
						f.refresh();
					}
				}
			}

			if (f.df.mandatory_depends_on) {
				this.set_dependant_property(
					f.df.mandatory_depends_on,
					f.df.fieldname,
					"reqd"
				);
			}

			if (f.df.read_only_depends_on) {
				this.set_dependant_property(
					f.df.read_only_depends_on,
					f.df.fieldname,
					"read_only"
				);
			}
		}

		this.refresh_section_count();
	}

	set_dependant_property(condition, fieldname, property) {
		let set_property = this.evaluate_depends_on_value(condition);
		let value = set_property ? 1 : 0;
		let form_obj;

		if (this.frm) {
			form_obj = this.frm;
		} else if (this.is_dialog || this.doctype === "Web Form") {
			form_obj = this;
		}
		if (form_obj) {
			if (this.doc && this.doc.parent && this.doc.parentfield) {
				form_obj.setting_dependency = true;
				form_obj.set_df_property(
					this.doc.parentfield,
					property,
					value,
					this.doc.parent,
					fieldname,
					this.doc.name
				);
				form_obj.setting_dependency = false;
				// refresh child fields
				this.fields_dict[fieldname] && this.fields_dict[fieldname].refresh();
			} else {
				form_obj.set_df_property(fieldname, property, value);
			}
		}
	}

	evaluate_depends_on_value(expression) {
		let out = null;
		let doc = this.doc;

		if (!doc && this.get_values) {
			doc = this.get_values(true);
		}

		if (!doc) {
			return;
		}

		let parent = this.frm ? this.frm.doc : this.doc || null;

		if (typeof expression === "boolean") {
			out = expression;
		} else if (typeof expression === "function") {
			out = expression(doc);
		} else if (expression.substr(0, 5) == "eval:") {
			try {
				out = frappe.utils.eval(expression.substr(5), { doc, parent });
				if (parent && parent.istable && expression.includes("is_submittable")) {
					out = true;
				}
			} catch (e) {
				frappe.throw(__('Invalid "depends_on" expression'));
			}
		} else if (expression.substr(0, 3) == "fn:" && this.frm) {
			out = this.frm.script_manager.trigger(
				expression.substr(3),
				this.doctype,
				this.docname
			);
		} else {
			var value = doc[expression];
			if ($.isArray(value)) {
				out = !!value.length;
			} else {
				out = !!value;
			}
		}

		return out;
	}
};
